import { PanelControllerConfig } from '@wix/blocks-widget-services-types';
import { IAppData } from '@wix/native-components-infra/dist/src/types/types';
import { assertModuleLoader } from '../utils/assertions';
import { CreatePanelFn } from '../createPanelController';
import { doesFileExists } from '../utils/fetchUtils';
import { BlocksPreviewAppData } from '../../../blocks-widget-services-types/src/widget';
import {
  generatePanelCodeUrl,
  generatePreviewWidgetCodeUrl,
} from '../utils/urlBuilder';
import { loadWidgetModule } from '../utils/importScriptAsAmdModule';
import { loadBundledCode } from '../utils/importScriptBundledCodeModule';
import {
  createRemotePanelController,
  PanelModule,
} from '../createRemotePanelController';
import { CreateClientParams } from './IWidgetModuleProvider';

type CreatePanelFnModule = {
  default: CreatePanelFn;
};
export class RemotePanelLoader {
  constructor(private appParams: IAppData<BlocksPreviewAppData>) {}

  isPanelBobApp = (controllerConfig: PanelControllerConfig): boolean =>
    !!controllerConfig.config.artifactId;

  getPanelCodeFromQuery = (controllerConfig: PanelControllerConfig): string =>
    controllerConfig.wixCodeApi.location.query.codeBundleUrl;

  private async loadVeloPanelCode(
    controllerConfig: PanelControllerConfig,
    createSdkParams?: () => CreateClientParams,
  ) {
    const { config } = controllerConfig;

    const widgetCodeUrl = generatePreviewWidgetCodeUrl(
      config.type,
      this.appParams,
    );
    const panelCodeFromQuery = this.getPanelCodeFromQuery(controllerConfig);

    const panelModule = panelCodeFromQuery
      ? (await loadBundledCode<PanelModule>(panelCodeFromQuery)).init
      : loadWidgetModule<PanelModule>(widgetCodeUrl);

    return createRemotePanelController(
      controllerConfig,
      panelModule,
      createSdkParams,
    );
  }

  private async loadBobPanelCode(controllerConfig: PanelControllerConfig) {
    assertModuleLoader(controllerConfig.wixCodeApi);

    const panelCodeUrl = generatePanelCodeUrl(controllerConfig);
    if (!panelCodeUrl) {
      return;
    }

    const doesFileExist = await doesFileExists(
      controllerConfig.essentials.httpClient,
      panelCodeUrl,
    );
    if (!doesFileExist) {
      console.info(
        'Remote BoB Panel code file does not exist. Falling back to velo code',
      );
      return;
    }

    const importAMDModule =
      controllerConfig.wixCodeApi.environment.network.importAMDModule;

    const panelControllerCreator = (
      await importAMDModule<CreatePanelFnModule>(panelCodeUrl)
    ).default;

    return panelControllerCreator(controllerConfig);
  }

  async loadPanel(
    controllerConfig: PanelControllerConfig,
    createSdkParams?: () => CreateClientParams,
  ) {
    const panelApp =
      this.isPanelBobApp(controllerConfig) &&
      (await this.loadBobPanelCode(controllerConfig));

    const result =
      panelApp || this.loadVeloPanelCode(controllerConfig, createSdkParams);
    return result;
  }
}
